const images =[
{id:0, src: process.env.PUBLIC_URL+'/images/photo0.jpg', title:'DQ', description:'Taki and Anna at DQ'},
{id:1, src: process.env.PUBLIC_URL+'/images/photo1.jpg', title:'Tattoo', description:'forearm tattoo'},
{id:2, src: process.env.PUBLIC_URL+'/images/photo2.jpg', title:'Derp', description:'Derpy Taki'},
{id:3, src: process.env.PUBLIC_URL+'/images/photo3.jpg', title:'Smile', description:'look at that face'},
{id:4, src: process.env.PUBLIC_URL+'/images/photo4.jpg', title:'Fab', description:'Randy Savage, is that you?'},
{id:5, src: process.env.PUBLIC_URL+'/images/photo5.jpg', title:'annoyed', description:'annoyed man with ear flap hat'},
{id:6, src: process.env.PUBLIC_URL+'/images/photo6.jpg', title:'mugging', description:'staring you down'},
{id:7, src: process.env.PUBLIC_URL+'/images/photo7.jpg', title:'haunted', description:'haunted house'},
{id:8, src: process.env.PUBLIC_URL+'/images/photo8.jpg', title:'mean mugging', description:' welcome back to TRU'},
{id:9, src: process.env.PUBLIC_URL+'/images/photo9.jpg', title:'abandoned', description:'abandoned structure'},
{id:10, src: process.env.PUBLIC_URL+'/images/photo10.jpg', title:'delicious', description:'deep and delicious :)'},
{id:11, src: process.env.PUBLIC_URL+'/images/photo11.jpg', title:'formal', description:'fancy people'},
{id:12, src: process.env.PUBLIC_URL+'/images/photo12.jpg', title:'sportsball', description:'SPORTS!'},
{id:13, src: process.env.PUBLIC_URL+'/images/photo13.jpg', title:'bird', description:'flipping the bird'},
{id:14, src: process.env.PUBLIC_URL+'/images/photo14.jpg', title:'snacks', description:'common heritage'},
{id:15, src: process.env.PUBLIC_URL+'/images/photo15.jpg', title:'beach', description:'the greek at the beach'},
{id:16, src: process.env.PUBLIC_URL+'/images/photo16.jpg', title:'degree', description:'your mom is proud'},
{id:17, src: process.env.PUBLIC_URL+'/images/photo17.jpg', title:'academic', description:'cap and no gown'},
];

export default images;